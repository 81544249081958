#connexion {
  background-image: url(../../../medias/bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
#headerConnexion {
  margin: 0px auto;
  text-align: center;
  background-color: white;
  opacity: 0.85 !important;
  padding: 2px;
}
#headerConnexion h1 {
  color: black;
  font-size: 40px;
  margin: 5px auto;
  letter-spacing: 8px;
}
#headerConnexion p {
  font-size: 14px;
  margin: 0 auto;
}
.tech_title {
  font-size: 40px;
  color: red;
}
#connectLink {
  color: white;
  font-weight: 500;
  text-decoration: none;
}
