#equipementedit {
  width: 100vw;
  height: 100%;
  background-image: url(../../../medias/bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
@media (min-width: 320px) {
  .MuiContainer-root {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.content {
  margin-bottom: 60px;
}
