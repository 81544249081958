#signin .paper {
  margin-top: 20px;
  align-items: "center";
  padding: 20px;
  background-color: white;
  opacity: 0.9;
}
#signin .title {
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 2px;
  margin: 5px;
}
#signin .form {
  width: 100%;
  margin-top: 4px;
  text-align: center;
}
#signin .form input {
  /* font-size: 14px; */
}
#signin .paper .form {
  margin: 0 auto;
  padding: 10px;
}
#signin .submit a {
  color: white;
  font-weight: bold;
}
#signin .connect {
  text-decoration: none;
}
