#container {
  background-color: white;
}

#container ul {
  margin-left: -40px;
  display: flex;
  justify-content: center;
  list-style: none;
}

#container ul li {
  margin: 10px;
  cursor: pointer;
}

.items {
  margin-bottom: 1rem;
}

.selected a {
  font-weight: bold;
  padding: 8px;
  background-color: rgb(191, 219, 255);
  border-radius: 10px;
}
