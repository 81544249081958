#equipements {
  width: 100vw;
  height: 100%;
  background-image: url(../../../medias/bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  font-size: 14px;
}
@media (min-width: 320px) {
  .MuiContainer-root {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
#btnAdd {
  text-align: center;
  background-color: white;
  height: 40px;
}
#equipements .addImg {
  padding-top: 10px;
  padding-bottom: 60px;
}
#equipements .circle {
  width: 16px;
  height: 16px;
  margin: 0 auto;
  border-radius: 50px;
}
#equipements .circlePuce {
  font-weight: 400;
  font-size: 18px;
  color: white;
}
