#techniciens {
  width: 100vw;
  height: 100vh;
  background-image: url(../../../medias/bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  font-size: 14px;
}
@media (min-width: 320px) {
  .MuiContainer-root {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.no-equipements {
  padding: 15px;
  font-size: 16px;
  letter-spacing: 1px;
}
.circle {
  width: 16px;
  height: 16px;
  margin: 0 auto;
  border-radius: 50px;
}
