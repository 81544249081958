#navbar {
  padding: 14px;
  background-color: white;
  margin: 0 auto;
}
#navbar li {
  padding: 2px;
  list-style: none;
  text-align: center;
  font-size: 18px;
}
.menu_burger {
  width: 32px;
  height: 32px;
  float: left;
}
/* NavBar Fuu size */

#navbarFull {
  height: 60px;
  padding: 14px;
  background-color: white;
  margin: 0 auto;
}
#navbarFull .linkNav {
  font-size: 18px;
  font-weight: 300;
  color: gray;
}
#navbarFull a:hover {
  color: black;
}
#logout_linkFull {
  font-size: 18px;
  font-weight: 300;
  color: red !important;
}
