#mostrecents {
  background-color: white;
  padding-bottom: 10px;
  font-size: 12px;
}
@media (min-width: 320px) {
  .MuiContainer-root {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.filter {
  background-color: #da292c;
  padding: 5px;
}
.filter p {
  color: white;
  letter-spacing: 1px;
  margin: 0 auto;
  padding-left: 10px;
  font-size: 12px;
}
.filter_grey {
  background-color: #6e6e6e;
  padding: 5px;
}
.filter_grey p {
  color: white;
  margin: 0 auto;
  padding-left: 15px;
  font-size: 16px;
}
#mostrecents .circle {
  width: 16px;
  height: 16px;
  margin: 0 auto;
  border-radius: 50px;
}
#mostrecents .circlePuce {
  font-weight: 400;
  font-size: 18px;
  color: white;
}
