#etalon {
  width: 100vw;
  height: 100vh;
  background-image: url(../../../medias/bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  font-size: 14px;
}
@media (min-width: 320px) {
  .MuiContainer-root {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
#etalon .valueLabel {
  font-size: 18px;
  margin: 5px;
}
#filled-basic {
  text-align: center;
  color: red;
  font-weight: bold;
  font-size: 20px;
  padding: 10px;
}
#resultLabel {
  font-size: 18px;
  margin: 5px;
}
#result {
  color: #00a7b5;
  font-weight: bold;
  font-size: 28px;
  margin: 10px;
}
