#menu {
  width: 100vw;
  height: 100vh;
  background-image: url(../../medias/bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
#img_elec {
  min-width: 320px;
  min-height: 120px;
  background-image: url(../../medias/bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}
#menu_manager {
  background-color: white;
}
#menu_manager ul {
  margin: 0;
  padding-top: 18px;
  padding-bottom: 20px;
}
#menu_manager li {
  padding: 10px;
  list-style: none;
  text-align: left;
  font-size: 20px;
}
a {
  color: black;
  font-weight: 300;
  text-decoration: none;
}
#logout_link {
  color: red;
}
