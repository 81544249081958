#loged {
  width: 100%;
  background-color: rgb(34, 34, 34);
}
#loged ul {
  padding: 8px;
  margin: 0;
}
.auth-name {
  font-size: 20px;
  padding-left: 5px;
  letter-spacing: 2px;
  margin: 0 auto;
  color: white;
}
.auth-role {
  padding-left: 50px;
  margin: 0 auto;
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: 400;
  color: white;
}
