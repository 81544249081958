#stats {
    padding: 20px;
    background-color: #dfdfdf;
    padding-bottom: 60px;
}
@media (min-width: 320px) {
    .MuiContainer-root {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
#stats p {
    margin: 0 auto;
    padding-left: 20px;
    font-size: 16px;
    font-weight: 400;
}
#stats td {
    font-size: 14px;
    font-weight: 300;
}
.tab {
    margin-left: 30px;
}
#stats .infos {
    padding-left: 50px;
    font-weight: 400;
}
table {
    margin: 0 auto;
    margin-bottom: 0;
}