#footer {
  width: 100vw;
  height: 60px;
  background-color: white;
  text-align: right;
  position: fixed;
  z-index: 99;
  bottom: 0px;
  left: 0px;
}
#footer h4 {
  font-size: 22px;
  margin: 0 auto;
  font-family: Arial, Helvetica, sans-serif;
  padding-right: 5%;
  padding-top: 15px;
  letter-spacing: 2px;
}
.footer_title {
  color: red;
}
